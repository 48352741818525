import React from "react"

import SEO from "../../components/seo"
import Layout from "../../components/Layouts/Base"
import Sidebar from "../../components/Integrations/Sidebar"

import headerImg from "../../images/integrations/zoom/header.png"
import img1 from "../../images/integrations/zoom/1.png"
import img2 from "../../images/integrations/zoom/2.png"
import img3 from "../../images/integrations/zoom/3.png"
import img4 from "../../images/integrations/zoom/4.png"

export const query = graphql`
  query ZoomIntegrationQuery {
    allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      skip: 0
      limit: 100
      filter: { showInSidebar: { eq: true } }
    ) {
      edges {
        node {
          slug {
            current
          }
          title
        }
      }
    }
  }
`

const IntegrationZoomPage = ({ data }) => {
  const posts = data.allSanityPost.edges

  return (
    <Layout>
      <SEO
        title="Accept Payments & Subscriptions in Zoom Calls with Payhere"
        description={
          "Create payment links to get paid online. Launch a one-page website with payments built in. Embed or integrate with 1000s of platforms using our SDKs or Zapier integration."
        }
        keywords={[
          `payments`,
          `online payments`,
          `card payments`,
          `recurring payments`,
          `subscriptions`,
          `recurring donations`,
          `donations`,
          `stripe`,
        ]}
      />

      <div className="max-w-5xl mx-auto px-6 py-10 lg:pb-16">
        <div className="md:grid md:grid-cols-12 md:gap-10">
          <Sidebar posts={posts} />
          <div className="md:col-span-8">
            <h1 className="text-2xl font-display text-black font-bold leading-8 md:text-4xl sm:leading-tight">
              Accept Payments & Subscriptions in Zoom Calls with Payhere
            </h1>

            <div className="prose mt-6">
              <img src={headerImg} className="" />

              <p>
                Need a simple way to get paid on Zoom? It's easy with Payhere!
              </p>
              <p>
                Once you have created your payment link, simply drop the URL
                link into the chat when you are on the call. Below is a step by
                step guide on how to do so!
              </p>
              <h4>
                1. Create your payment link using Payhere, choosing any of the
                following.
              </h4>
              <p>
                <strong>Fixed amount</strong>
              </p>
              <ul>
                <li>Recurring (weekly, monthly, quarterly or yearly)</li>
                <li>One-off</li>
              </ul>
              <p>
                <strong>Donation amount (customer will enter amount)</strong>
              </p>
              <ul>
                <li>Recurring (weekly, monthly, quarterly or yearly)</li>
                <li>One-off</li>
              </ul>
              <img src={img1} className="" />
              <h4>2. Copy the link that we have created for you.</h4>
              <img src={img2} className="" />
              <h4>3. Drop the link into the Zoom chat.</h4>
              <img src={img3} className="" />
              <h4>
                4. Link is opened, details entered and the payment is made!
              </h4>
              <img src={img4} className="" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IntegrationZoomPage
